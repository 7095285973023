
import { Survey } from '@/models/count';
import { ClientEvent, Household, JoinTeamEvent, SubmissionWrapper, VolunteerRegistration } from '@/models/survey';
import { DownloadService } from '@/services/misc/download';
import { DataService } from '@/services/pure/data';
import * as _ from 'lodash';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    id: {
      type: String,
    },
    submissions: {
      type: Array as PropType<Household[]>,
    },
    submissionsGroupedByVolunteer: {
      type: Object as PropType<any>,
    },
    registrations: {
      type: Array as PropType<VolunteerRegistration[]>,
    },
  },
  data() {
    return {
      search: '',
      selected: [],
    };
  },
  computed: {
    userEvents(): ClientEvent[] {
      const eventsMap = this.$store.getters.events;
      const events: ClientEvent[] = eventsMap[this.id] ?? [];

      if (events.length === 0) return [];
      const mostRecent = _.orderBy(events, item => item.date, [
        'desc',
      ]);
      const userEvents = _.uniqBy(mostRecent, event => event.identifier);
      return userEvents;
    },
    headers(): { text: string; value: string; sortable?: boolean }[] {
      return [
        { text: 'Name', value: 'Name' },
        { text: 'Phone', value: 'Phone' },
        { text: 'Submissions', value: 'Submissions' },
        { text: 'Last Submission', value: 'LastSubmission' },
        ...(this.hasTeams ? [{ text: 'Team', value: 'Team' }] : []),
        { text: 'Notes', value: 'Notes' },
        { text: 'Details', value: 'actions', sortable: false },
      ];
    },
    hasTeams(): boolean {
      const survey: Survey = this.$store.get('survey/survey');
      return !!survey.regionMap;
    },
    data(): Display[] {
      const volunteersWithSubmissions = this.getVolunteersWithSubmissions(this.submissionsGroupedByVolunteer);
      const volunteersWithoutSubmissions = this.getVolunteersWithoutSubmissions(this.userEvents, this.submissions);
      return [...volunteersWithSubmissions, ...volunteersWithoutSubmissions];
    },
  },
  methods: {
    getVolunteersWithSubmissions(submissionsGroupedByVolunteer: any) {
      const toDisplay: Display[] = [];
      for (let volunteerIdentifier in submissionsGroupedByVolunteer) {
        const submissions: Household[] = submissionsGroupedByVolunteer[
          volunteerIdentifier
        ];
        const mostRecent = _.orderBy(submissions, item => item.date, [
          'desc',
        ])[0];
        const item: Display = {
          Name: mostRecent.volunteerName,
          Phone: DataService.formatPhone(mostRecent.volunteerPhone),
          Submissions: submissions.length,
          LastSubmission: new Date(mostRecent.date),
          ID: mostRecent.volunteerID,
          Notes: mostRecent.latlng === 'Unknown' ? 'Not Sharing Location' : '',
          Team: mostRecent.teamName,
        };
        toDisplay.push(item);
      }
      return toDisplay;

    },
    getVolunteersWithoutSubmissions(events: ClientEvent[], submissions: Household[]): Display[] {
      const withoutSubmissions = events.filter(e => {
        if (submissions.find(s => s.volunteerID === e.identifier)) return false;
        return true;
      });

      const filteredByQuery = this.filterByQuery(withoutSubmissions);

      return filteredByQuery.map(ws => {
        return {
          Name: ws.volunteerName,
          Phone: DataService.formatPhone(ws.volunteerPhone),
          Submissions: 0,
          ID: ws.identifier,
          Notes: '',
          Team: ws.type === 'team' ? ws.team : '',
        }
      })

    },
    filterByQuery(events: ClientEvent[]) {
      let regions = this.$route.query.regions;
      if (!regions) return events;
      if (!Array.isArray(regions)) regions = [regions];
      const regionsAsArray = regions as string[];
      return events.filter(s => {
        if (s.type === 'login') return false;
        return !!regionsAsArray.find((r: string) => r === s.team);


      });
    },
    filterToVolunteer(item: Display) {
      this.$router.replace({
        query: { ...this.$route.query, tab: 'Data', volunteer: item.Name },
      });
    },
    async download() {
      // Include date of registration and order by it.
      // Map questions and answers to labels.
      const mapped = this.registrations?.length ? DataService.mapVolunteerRegistrationsForExport(
        this.registrations,
        this.id,
      ) : DataService.mapVolunteersForExport(
        this.data,
      );
      await DownloadService.downloadCSV(mapped, 'volunteers.csv');
    },
  },
});
