import { FormWrapper } from './../models/formstructure';
import { CountFactory } from './../services/shared/surveys';
import { Dashboard } from './../models/dashboard';
import { LoggingService } from './../services/logging';
import { ShelterSet, ShelterSetWithId } from './../models/all';
import _ from 'lodash';
import firebase, { firestore } from 'firebase/app';
require('firebase/firestore');
require('firebase/auth');
import Store from '../store';
import { User } from '../models/User';
import { Survey, SurveyWithCode, SurveyMode, TeamAssignment } from '../models/count';
import { ClientEvent, SubmissionWrapper, VolunteerRegistration } from '../models/survey';
import { AdminAdjustment, Duplicate } from '../models/adminadjustment';
import { Admin, AdminWithoutID, Lead, OrganizationPermission, RegionMap } from '../models/Organization';
import { DashboardSettings } from '../models/dashboard';
import { onError } from 'knockout';

export const FirebaseRepository = {
  async updateUseUUIDs(useUUIDs: boolean) {
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    const db = firebase.firestore();
    try {
      await db
        .doc(`preferences/${user.uid}`)
        .set({
          preferences: { useUUIDs }
        }, { merge: true });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  },
  async getCoCShapefileUrl(coc: string) {
    const cocForUrl = coc.replace('-', '_');
    const storage = firebase.storage();
    const url = await storage.ref(`coc_boundary/${cocForUrl}.geojson`).getDownloadURL();
    return url;
  },
  async saveTeamAssignments(code: string, teamAssignments: TeamAssignment[]) {
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    const organizationID = await this.getAdminOrganizationID(user);
    const db = firebase.firestore();
    try {
      await Promise.all(teamAssignments.map(
        ta => {
          if (ta.Team) {
            db.doc(`organizations/${organizationID}/counts/${code}/volunteerRegistration/${ta.ID}`).update({ team: ta.Team })
          }
        }));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async uploadHotspots(code: string, content: string) {
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    const organizationID = await this.getAdminOrganizationID(user);
    const db = firebase.firestore();
    try {
      await db
        .doc(`organizations/${organizationID}/counts/${code}/hotspots/all`)
        .set({
          hotspots: content
        });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  },
  async getRegionShapefiles() {
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    const storage = firebase.storage();
    const organizationID = await this.getAdminOrganizationID(user);
    const ref = storage.ref(`subregion/${organizationID}`);
    const result = await ref.list({});
    return result;
  },
  async uploadRegionShapefile(name: string, arrayBuffer: ArrayBuffer) {
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    const storage = firebase.storage();
    const organizationID = await this.getAdminOrganizationID(user);
    const ref = storage.ref(`subregion/${organizationID}/${name}`);
    const result = await ref.put(arrayBuffer);
    return result.bytesTransferred > 0;

  },
  async sendResetEmail(
    auth: firebase.auth.Auth,
    email: string,
  ): Promise<{ errorMessage: string; success: boolean }> {
    try {
      await auth.sendPasswordResetEmail(email);
      return { errorMessage: '', success: true };
    } catch (error) {
      if (typeof error == 'object' && error !== null && 'message' in error)
        return { errorMessage: (error as Record<string, string>)?.message, success: false };
      return { errorMessage: "", success: false }
    }
  },
  async setOrganizationName(name: string): Promise<boolean> {
    const db = firebase.firestore();
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    try {
      const organizationID = await this.getAdminOrganizationID(user);
      await db
        .collection('organizations')
        .doc(organizationID)
        .update({
          Name: name,
        });
    } catch (error) {
      return false;
    }
    return true;
  },
  async addRegions(data: any, name: string) {
    const db = firebase.firestore();
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    const asString = JSON.stringify(data);

    try {
      const organizationID = await this.getAdminOrganizationID(user);
      await db
        .collection(`organizations/${organizationID}/regions`)
        .add({ regions: asString, name })
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  },
  async addShelters(shelterSet: ShelterSet) {
    const db = firebase.firestore();
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    try {
      const organizationID = await this.getAdminOrganizationID(user);
      await db
        .collection(`organizations/${organizationID}/shelters`)
        .add(shelterSet)
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  },
  async deleteShelter(shelterSet: ShelterSetWithId) {
    const db = firebase.firestore();
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }

    try {
      const organizationID = await this.getAdminOrganizationID(user);
      await db
        .doc(`organizations/${organizationID}/shelters/${shelterSet.id}`)
        .delete();
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  },
  async deleteRegion(region: RegionMap) {
    const db = firebase.firestore();
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }

    try {
      const organizationID = await this.getAdminOrganizationID(user);
      await db
        .doc(`organizations/${organizationID}/regions/${region.ID}`)
        .delete();
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  },
  async setCoC(coc: string): Promise<boolean> {
    const db = firebase.firestore();
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    try {
      const organizationID = await this.getAdminOrganizationID(user);
      await db
        .collection('organizations')
        .doc(organizationID)
        .update({
          CoC: coc,
        });
    } catch (error) {
      return false;
    }
    return true;
  },
  async setLeadRegions(organizationID: string, countID: string, leads: Lead[]): Promise<boolean> {
    const db = firebase.firestore();
    try {
      await db
        .doc(`organizations/${organizationID}/counts/${countID}`)
        .update({
          leads
        });
    } catch (error) {
      return false;
    }
    return true;

  },
  async setUserPermissionsInOrganization(uid: string, organizationID: string, permission: OrganizationPermission): Promise<boolean> {
    const db = firebase.firestore();
    try {
      await db
        .collection(`organizations/${organizationID}/users`)
        .doc(uid)
        .set({
          permission
        }, { merge: true });
    } catch (error) {
      LoggingService.Log('Error setting user permissions')
      LoggingService.Log(error)
      return false;
    }
    return true;

  },
  async removeUserFromOrganization(uid: string, organizationID: string): Promise<boolean> {
    const db = firebase.firestore();
    try {
      await db
        .collection(`organizations/${organizationID}/users`)
        .doc(uid)
        .delete()
    } catch (error) {
      return false;
    }
    return true;

  },

  async getAdminOrganizationID(user: firebase.User | null): Promise<string> {
    if (!!Store.getters.organizationID) return Store.getters.organizationID;
    if (user === null) return '';
    const userData = await getDocData('users', user.uid);
    const organizationID = userData!.AdminOf[0];
    Store.commit('organizationID', organizationID);
    return organizationID;
  },
  async getName(organizationID: string): Promise<string> {
    const data = await getDocData('organizations', organizationID);
    const name = data && data.Name;
    return name;
  },
  async getUser(userID: string): Promise<User> {
    const userData = await getDocData('users', userID);
    return {
      name: userData!.Name,
      phoneNumber: userData!.PhoneNumber,
    };
  },
  async getOrganizationID(): Promise<string> {
    const user = await firebase.auth().currentUser;
    const organizationID = await this.getAdminOrganizationID(user);
    return organizationID;
  },
  async saveFormStructures(formStructures: FormWrapper[], idsRemoved: string[]): Promise<boolean> {
    const db = firebase.firestore();
    const user = await firebase.auth().currentUser;
    if (!user) {
      return false;
    }
    const organizationID = await this.getAdminOrganizationID(user);
    await Promise.all(
      formStructures.map(async form => {
        const path = `organizations/${organizationID}/forms/${form.ID}`;
        await db.doc(path).set(form);
      }),
    );
    await Promise.all(
      idsRemoved.map(async id => {
        const path = `organizations/${organizationID}/forms/${id}`;
        await db.doc(path).delete();
      }),
    );
    return true;
  },
  async createCount(survey: Survey, organizationID: string, code: string) {
    const db = firebase.firestore();

    await db
      .collection(`organizations/${organizationID}/counts`)
      .doc(code)
      .set(survey);
  },

  async updateCount(organizationID: string, survey: SurveyWithCode) {
    const db = firebase.firestore();
    const newSurvey = {
      mode: survey.mode,
      name: survey.name,
      hmisMappings: survey.hmisMappings,
      includeObservationalInExport: survey.includeObservationalInExport,
      observationalSurvey: survey.observationalSurvey,
      interviewSurvey: survey.interviewSurvey,
      volunteerSignupSurvey: survey.volunteerSignupSurvey
    };

    // Otherwise if these are undefined, firebase will kick back an error.
    if (!newSurvey.observationalSurvey)
      delete newSurvey.observationalSurvey;
    if (!newSurvey.volunteerSignupSurvey)
      delete newSurvey.volunteerSignupSurvey;
    await db.collection(`organizations/${organizationID}/counts`)
      .doc(survey.code)
      .update(newSurvey);
  },
  async deleteCount(code: string, organizationID: string) {
    const db = firebase.firestore();
    db.collection(`organizations/${organizationID}/counts`)
      .doc(code)
      .delete();
  },
  async listenToMyTeamSubmissions(code: string, organizationID: string) {
    // We can assume here that I am assigned regions because otherwise I wouldn't have seen
    // the link to click on (filtering surveys/counts in store).
    const survey: Survey = Store.getters.Survey(code);
    const me = Store.getters.user;
    const meInSurvey = survey.leads?.find(s => s.ID === me.email);

    const myRegions = meInSurvey!.regions as string[];
    return myRegions.map(teamName => {
      const path = `organizations/${organizationID}/counts/${code}/teams/${teamName}/submissions`;
      const db = firebase.firestore();
      return db
        .collection(path)
        .onSnapshot(async (snapshot: firestore.QuerySnapshot) => {
          if (!Store.getters.loggedIn) return;
          const data = await Promise.all(
            snapshot.docs.map(
              async (d: firestore.QueryDocumentSnapshot) => await d.data(),
            ),
          );
          const surveys: SubmissionWrapper[] = _.flatten(
            data.map(d => (d!.surveys ? d!.surveys : [])),
          );
          Store.commit('unionSubmissions', { code, submissions: surveys });
        }, (error: firestore.FirestoreError) => {
          console.error('error listening to team submissions', error);
        });
    })

  },
  async listenToSubmissions(code: string, organizationID: string) {
    const path = `organizations/${organizationID}/counts/${code}/submissions`;
    const db = firebase.firestore();
    return db
      .collection(path)
      .onSnapshot(async (snapshot: firestore.QuerySnapshot) => {
        if (!Store.getters.loggedIn) return;
        const data = await Promise.all(
          snapshot.docs.map(
            async (d: firestore.QueryDocumentSnapshot) => await d.data(),
          ),
        );
        const surveys: SubmissionWrapper[] = _.flatten(
          data.map(d => (d!.surveys ? d!.surveys : [])),
        );
        Store.commit('setSubmissions', { code, submissions: surveys });
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to submissions', error);
      });
  },
  async listenToOrganizationUsers(organizationID: string) {
    const path = `organizations/${organizationID}/users/`;
    const db = firebase.firestore();
    return db
      .collection(path)
      .onSnapshot(async (snapshot: firestore.QuerySnapshot) => {
        if (!Store.getters.loggedIn) return;
        const admins: Admin[] = await Promise.all(
          snapshot.docs.map(
            async (d: firestore.QueryDocumentSnapshot) => {
              const data = await d.data() as AdminWithoutID;
              return { ID: d.id, ...data };
            }
          ),
        );
        Store.commit('setAdmins', admins);
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to organization users', error);
      });
  },
  async listenToRegions(organizationID: string) {
    const path = `organizations/${organizationID}/regions/`;
    const db = firebase.firestore();
    return db
      .collection(path)
      .onSnapshot(async (snapshot: firestore.QuerySnapshot) => {
        if (!Store.getters.loggedIn) return;
        const regions: RegionMap[] = await Promise.all(
          snapshot.docs.map(
            async (d: firestore.QueryDocumentSnapshot) => {
              const data = await d.data();
              return { ID: d.id, name: data.name, regions: JSON.parse(data.regions) }
            },
          ),
        );
        (Store as any).set('organization/regions', regions);
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to regions', error);
      });
  },
  async listenToShelters(organizationID: string) {
    const path = `organizations/${organizationID}/shelters/`;
    const db = firebase.firestore();
    return db
      .collection(path)
      .onSnapshot(async (snapshot: firestore.QuerySnapshot) => {
        if (!Store.getters.loggedIn) return;
        const shelters: ShelterSetWithId[] = await Promise.all(
          snapshot.docs.map(
            async (d: firestore.QueryDocumentSnapshot) => {
              const data = await d.data();
              return { id: d.id, ...data } as ShelterSetWithId
            },
          ),
        );
        (Store as any).set('organization/shelters', shelters);
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to shelters', error);
      });
  },
  async listenToDuplicates(code: string, organizationID: string) {
    const path = `organizations/${organizationID}/counts/${code}/duplicates/duplicates`;
    const db = firebase.firestore();
    return db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        if (!Store.getters.loggedIn) return;
        const data = await snapshot.data();
        const duplicates: Duplicate[] = data && data.duplicates;
        const toSave = duplicates ? duplicates : [];
        Store.commit('setDuplicates', { code, duplicates: toSave });
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to duplicates', error);
      });
  },
  async listenToEvents(code: string, organizationID: string) {
    const path = `organizations/${organizationID}/counts/${code}/events/0`;
    const db = firebase.firestore();
    return db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        if (!Store.getters.loggedIn) return;
        const data = await snapshot.data();
        const events: ClientEvent[] = data && data.events;
        const toSave = events ? events : [];
        Store.commit('setEvents', { code, events: toSave });
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to events', error);
      });
  },
  async listenToVolunteerRegistrations(code: string, organizationID: string) {
    const path = `organizations/${organizationID}/counts/${code}/volunteerRegistration`;
    const db = firebase.firestore();
    return db
      .collection(path)
      .onSnapshot(async (snapshot: firestore.QuerySnapshot) => {
        if (!Store.getters.loggedIn) return;
        const volunteerRegistrations: VolunteerRegistration[] = await Promise.all(
          snapshot.docs.map(
            async (d: firestore.QueryDocumentSnapshot) => {
              const data = await d.data();
              return { id: d.id, ...data } as VolunteerRegistration
            },
          ),
        );
        Store.set('setVolunteerRegistrations', {
          code,
          volunteerRegistrations
        });
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to volunteer registrations', error);
      });
  },
  async listenToDashboard(code: string) {
    const path = `dashboards/${code}`;
    const db = firebase.firestore();
    return db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        if (!Store.getters.loggedIn) return;
        const data = await snapshot.data() as Dashboard | undefined;
        Store.commit('setDashboard', { code, dashboard: data });
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to dashboard', error);
      });
  },
  async listenToLiveSurveys(code: string, organizationID: string) {
    const path = `organizations/${organizationID}/counts/${code}/productionSurveys/surveys`;
    const db = firebase.firestore();
    return db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        if (!Store.getters.loggedIn) return;
        const data = await snapshot.data();
        const liveSurveys: string[] = data && data.surveys;
        const toSave = liveSurveys ? liveSurveys : [];
        Store.commit('setLiveSurveys', {
          code,
          liveSurveys: toSave,
        });
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to live surveys', error);
      });
  },
  async listenToAdminAdjustments(code: string, organizationID: string) {
    const path = `organizations/${organizationID}/counts/${code}/adminAdjustments/adjustments`;
    const db = firebase.firestore();
    return db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        if (!Store.getters.loggedIn) return;
        const data = await snapshot.data();
        const liveSurveys: AdminAdjustment[] = data && data.adminAdjustments;
        const toSave = liveSurveys ? liveSurveys : [];
        Store.commit('setAdminAdjustments', {
          code,
          adminAdjustments: toSave,
        });
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to admin adjustments', error);
      });
  },
  async listenToHotspots(code: string, organizationID: string) {
    const path = `organizations/${organizationID}/counts/${code}/hotspots/all`;
    const db = firebase.firestore();
    return db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        if (!Store.getters.loggedIn) return;
        const data = await snapshot.data();
        const hotspots: AdminAdjustment[] = data && data.hotspots;
        const toSave = hotspots ? hotspots : [];
        Store.commit('setHotspots', {
          code,
          hotspots: toSave,
        });
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to hotspots', error);
      });
  },
  async listenToLocationLookup(code: string, organizationID: string) {
    const path = `organizations/${organizationID}/counts/${code}/locationLookup/all`;
    const db = firebase.firestore();
    return db
      .doc(path)
      .onSnapshot(async (snapshot: firestore.DocumentSnapshot) => {
        if (!Store.getters.loggedIn) return;
        const data = await snapshot.data();
        const toSave = data ?? {};
        Store.commit('setLocationLookup', {
          code,
          locationLookup: toSave,
        });
      }, (error: firestore.FirestoreError) => {
        console.error('error listening to location lookup', error);
      });
  },
  async setAdminAdjustments(
    code: string,
    organizationID: string,
    adminAdjustments: AdminAdjustment[],
  ) {
    const path = `organizations/${organizationID}/counts/${code}/adminAdjustments/adjustments`;
    const db = firebase.firestore();
    await db.doc(path).set({ adminAdjustments });
  },
  async setDuplicates(
    code: string,
    organizationID: string,
    duplicates: Duplicate[],
  ) {
    const path = `organizations/${organizationID}/counts/${code}/duplicates/duplicates`;
    const db = firebase.firestore();
    await db.doc(path).set({ duplicates });
  },
  async setDashboardSettings(
    code: string,
    settings: DashboardSettings,
  ) {
    const path = `dashboards/${code}`;
    const db = firebase.firestore();
    await db.doc(path).set({ settings });
  },
  async listen(): Promise<any> {
    const db = firebase.firestore();
    const user = await firebase.auth().currentUser;
    if (!user) {
      return [];
    }
    let i = 0;
    try {

      const organizationID = await this.getAdminOrganizationID(user);
      i++;
      const one = await listenToOrganizationChanges(db, organizationID);
      i++;
      const two = await listenToPreferences(db);
      i++;
      const three = await this.listenToOrganizationUsers(organizationID);
      i++;
      const four = await this.listenToRegions(organizationID);
      i++;
      const five = await this.listenToShelters(organizationID);
      i++;
      const six = await listenToCountChanges(db, organizationID);
      i++;
      const seven = await listenToFormChanges(db, organizationID);
      return [one, two, three, four, five, six, seven];
    } catch (err) {
      LoggingService.Log(`problem on step: ${i}`)
      throw err;
    }
  },
  async sendVerificationEmail(user: firebase.User | null): Promise<boolean> {
    if (!user) {
      return false;
    }
    return await user
      .sendEmailVerification()
      .then(() => {
        return true;
      })
      .catch(ex => {
        console.log(ex)
        return false;
      });
  },
};

async function listenToCountChanges(
  db: firestore.Firestore,
  organizationID: string,
): Promise<any> {
  const path = `organizations/${organizationID}/counts`;
  return await db
    .collection(path)
    .onSnapshot(async (snapshot: firestore.QuerySnapshot) => {
      const surveys = [] as SurveyWithCode[];
      for (const doc of snapshot.docs) {
        const code = doc.id;
        const data = await doc.data();
        const survey = CountFactory.create(data, code);
        surveys.push(survey);
      }
      Store.commit('updateSurveys', { surveys });
    });
}

async function listenToFormChanges(
  db: firestore.Firestore,
  organizationID: string,
): Promise<any> {
  const path = `organizations/${organizationID}/forms`;
  return await db
    .collection(path)
    .onSnapshot(async (snapshot: firestore.QuerySnapshot) => {
      const forms = [] as FormWrapper[];
      for (const doc of snapshot.docs) {
        const data = await doc.data();
        const form = data as FormWrapper;
        forms.push(form);
      }
      Store.commit('updateForms', { forms });
    });
}

async function listenToPreferences(
  db: firestore.Firestore,
): Promise<any> {
  const user = await firebase.auth().currentUser;
  if (!user) {
    return false;
  }
  const path = `preferences/${user.uid}`;
  return await db
    .doc(path)
    .onSnapshot(async (doc: firestore.DocumentSnapshot) => {
      let data = await doc.data();
      if (!data) data = { preferences: { useUUIDs: false } };
      Store.commit('updatePreferences', { preferences: data!.preferences });
    });
}

async function listenToOrganizationChanges(
  db: firestore.Firestore,
  organizationID: string,
): Promise<any> {
  const path = `organizations/${organizationID}`;
  const snapshot = await db.doc(path).get();
  const formsPath = `forms`;
  const formsSnapshot = await db.collection(formsPath).get();

  // Fetch forms that should exist for all organizations
  // and merge them.
  const forms = formsSnapshot.docs.map(doc => doc.data() as FormWrapper);
  handleOrganizationSnapshot(snapshot, forms);

  return await db
    .doc(path)
    .onSnapshot((snapshot: firestore.DocumentSnapshot) => {
      const forms = formsSnapshot.docs.map(doc => doc.data() as FormWrapper);
      handleOrganizationSnapshot(snapshot, forms);
    });
}

function handleOrganizationSnapshot(snapshot: firestore.DocumentSnapshot, forms: FormWrapper[]) {
  const data = snapshot.data();

  const both = addGeneralFormsToOrg(data!.Forms, forms);
  Store.commit('updateOrganization', {
    name: data!.Name,
    formStructures: both,
    hasObservation: data!.hasObservation,
    paidThrough: data!.paidThrough,
    enterprise: data!.enterprise ?? false,
    CoC: data!.CoC
  });
}

function addGeneralFormsToOrg(
  orgForms: FormWrapper[],
  generalForms: FormWrapper[],
): FormWrapper[] {
  const both = [...orgForms];
  for (const form of generalForms) {
    if (!orgForms.find(f => f.ID === form.ID)) {
      form.Edited = Date.now();
      both.push(form);
    }
  }
  return both;
}

async function getDoc(
  collection: string,
  id: string,
): Promise<firebase.firestore.DocumentReference | undefined> {
  const db = firebase.firestore();
  const doc = await db.collection(collection).doc(id);
  return await doc;
}

async function getDocData(
  collection: string,
  id: string,
): Promise<firebase.firestore.DocumentData | undefined> {
  const doc = await getDoc(collection, id);
  const dataRef = doc && (await doc.get());
  if (dataRef) {
    return dataRef.data();
  }
  return undefined;
}
